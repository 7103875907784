import { map as _map } from 'lodash-es'

import { IDateRangePair, IrisState, IrisGetters, IItemPurchased, PaymentMethods } from '@iris/store/types'
import { EmailData } from './feathersjs/index.js'
import Stripe from 'stripe'
import moment, { Duration } from 'moment-timezone'
import { PaymentPeriods } from '@iris/store/types'
import { PlanWithPaymentPeriodsTypes } from '@iris/store/types'
import { SubscriptionType } from './nestjs-interfaces-recurly'
import { PaymentTypes } from './store/payments/types.js'

export interface IrisPricingCode extends IDateRangePair {
  code: string;
}

export interface IrisPaygRevisionPricingOption {
  deposit: number;
  monthsTerm: number;
  total: number;
};

export interface PriceModel extends IDateRangePair {
  /** Which version of the price model is this */
  modelType: string
}

export interface EstiaLabsPricingModel extends PriceModel {
  modelType: 'estialabs',
  /** billing cycle length (usually 4 weeks) */
  billingCycleLength?: Duration,
  /** number of 1 on 1 tutor sessions included per billing cycle */
  includedSessions: number,
  /** has teacher helpline  */
  teacherHelpline: boolean,
  /** includes access to learning system  */
  includesLearningSystem: boolean
  /** price for extra sessions options */
  extraSessionCost: {
    /** number of sessions included for the cost */
    numberOfSessions: number
    /** cost for the number of sesions */
    cost: number
  }[],
  /** cost per billing cycle */
  membershipFee: number
  /** term length if applicable */
  termLength?: Duration

  priceCode: PaymentMethods

  description: string

  baseSubscriptionType: SubscriptionType

  /** has no direct debit  */
  noDirectDebit?: boolean

  upfrontPayment?: {
    period: Duration,
    amount: number
  }

  /** override the initial payment types allowed */
  initialPaymentTypes?: PaymentTypes[]
}

export type IrisPricingTypes = EstiaLabsPricingModel

export type IrisPromoCodes = IDateRangePair & {
  /** promotional code */
  code: string;
  /** a text description to show to the end user when applied */
  description?: string;
  /** is this code to be applied after initialising iris */
  appliedDefault?: boolean;
  /** code validator */
  isValid?(state: IrisState, getters: IrisGetters, rootState: IrisState, rootGetters: IrisGetters): boolean;
} & ({
  /** path in store to modify */
  path?: string;
  /** value of store applied when code is activated */
  valueApplied?: any;
  /** value of store applied when code is deactivated */
  valueDefault?: any;
} | {
  /** paths in store to modify */
  path?: string[];
  /** value of store applied when code is activated */
  valueApplied?: any[];
  /** value of store applied when code is deactivated */
  valueDefault?: any[];
})

// helpers for settings

type EnvSettings<T> = Array<{
  hostname: string | RegExp,
  value: T
}>

/**
 *
 * @param varName global var on window to fetch from
 * @param settings settings based upon hostname
 * @param defaultValue default value otherwise
 */
export function getSetting<T> (varName: string | null, settings: EnvSettings<T>, defaultValue: T): T {
  // ensure this works in nodejs without window reference
  if (typeof window === 'undefined') {
    return defaultValue
  }
  // @ts-ignore
  if (typeof varName === 'string' && (typeof (window[varName]) === 'string') && window[varName]) {
    // @ts-ignore
    return window[varName] as T
  }
  let foundKey = settings.find(({ hostname }) => typeof hostname === 'string' ? window.location.hostname === hostname : window.location.hostname.match(hostname))
  return foundKey ? foundKey.value : defaultValue
}

// spell-checker:enable

export const ASSESSMENTS_SERVER: string = getSetting('ASSESSMENTS_SERVER', [
  {
    value: 'http://127.0.0.1:3000/api',
    hostname: '127.0.0.1'
  }
], 'https://assessments.exemplar-education.com/api')


export const enum SIGNER_NAMES {
  CUSTOMER = 'CUSTOMER',
  ALTPAYER = 'ALTPAYER',
  DEPOSIT_ALT_PAYER = 'DEPOSIT_ALT_PAYER',
  DIRECT_DEBIT_ALT_PAYER = 'DIRECT_DEBIT_ALT_PAYER',
  CONSULTANT = 'CONSULTANT'
}
export type SIGNER_NAMES_TYPE = keyof typeof SIGNER_NAMES

export const CUSTOMER = SIGNER_NAMES.CUSTOMER
export const ALTPAYER = SIGNER_NAMES.ALTPAYER
export const CONSULTANT = SIGNER_NAMES.CONSULTANT
export const DIRECT_DEBIT_ALT_PAYER = SIGNER_NAMES.DIRECT_DEBIT_ALT_PAYER
export const DEPOSIT_ALT_PAYER = SIGNER_NAMES.DEPOSIT_ALT_PAYER

export const SIGNING_ORDER = [CUSTOMER, ALTPAYER, DEPOSIT_ALT_PAYER, DIRECT_DEBIT_ALT_PAYER, CONSULTANT] // NB deposit payer can be reused for direct debit details and will require this.

export const TIMEZONE = 'Europe/London'
export const TIME_FORMAT = 'YYYY-MM-DD h:mm:ssa zz'

export const DEFAULT_DEPOSIT_AMOUNT_IN_DOLLARS = 100

/**
 * Return a sender number for messagebird depending on the target number to send the message to
 * @param {string} number Number to test for
 */
export const MESSAGEBIRD_SENDER_FOR_NUMBER = (number: string): string => {
  if (number.match(/^61/)) {
    return '61488824064'
  }
  return '447418310307'
}

type ItemsForSaleType = Required<Omit<IItemPurchased, 'quantity'> & { invoiceDescription: string, description: string | ((amount: number) => string) }>

export const REFUND_EMAIL_TO = getSetting<EmailData['to']>('REFUND_EMAIL_TO', [], [
  'admin@exemplar-education.com',
  'stellag@exemplar-education.com'
])

export const ITEMS_FOR_SALE: ItemsForSaleType[] = []

/**
 * Quick function to make the term division different for both aps and payg
 * @param basePriceCents base price to work aps and payg pricing from
 */
const paygAndApsPriceFunction = (basePriceCents: number) => (modules: number) => {
  let term = 60
  if (modules < 7) {
    term = 48
  }
  return basePriceCents / term
}

// export type PaymentPeriods = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
// /** Plans with Payment methods types */
// export type PlanWithPaymentPeriodsTypes = 'P' | 'G' | 'S'
// /** combination of all payment methods */
// export type PaymentMethods = `EL${PlanWithPaymentPeriodsTypes}${PaymentPeriods}` | 'ELNM';


const PAYMENT_PERIODS:PaymentPeriods[] = [1,2,3,4,5,6,7,8,9,10,11,12]
const PERIOD_PLAN_TYPES:(Pick<EstiaLabsPricingModel, 'extraSessionCost' | 'includedSessions' | 'teacherHelpline' | 'description' | 'baseSubscriptionType'> & {
  type: PlanWithPaymentPeriodsTypes
})[] = [{
  type: 'P',
  description: 'Platinum',
  includedSessions: 4,
  teacherHelpline: true,
  baseSubscriptionType: SubscriptionType.ELP,
  extraSessionCost: [{
    cost: 15,
    numberOfSessions: 1
  }]
}, {
  type: 'G',
  description: 'Gold',
  includedSessions: 2,
  teacherHelpline: false,
  baseSubscriptionType: SubscriptionType.ELG,
  extraSessionCost: [{
    cost: 15,
    numberOfSessions: 1
  }]
}, {
  type: 'S',
  description: 'Silver',
  includedSessions: 1,
  teacherHelpline: false,
  baseSubscriptionType: SubscriptionType.ELS,
  extraSessionCost: [{
    cost: 20,
    numberOfSessions: 1
  }]
}]

const PERIOD_PLAN_TYPES_PILOT:(Pick<EstiaLabsPricingModel, 'extraSessionCost' | 'includedSessions' | 'teacherHelpline' | 'description' | 'baseSubscriptionType'> & {
  type: PlanWithPaymentPeriodsTypes,
  basePrice: number
})[] = [{
  type: 'P',
  description: 'Platinum',
  includedSessions: 4,
  teacherHelpline: true,
  baseSubscriptionType: SubscriptionType.ELP,
  basePrice: 59,
  extraSessionCost: [{
    cost: 15,
    numberOfSessions: 1
  }]
}, {
  type: 'G',
  description: 'Gold',
  includedSessions: 2,
  teacherHelpline: true,
  baseSubscriptionType: SubscriptionType.ELG,
  basePrice: 59,
  extraSessionCost: [{
    cost: 15,
    numberOfSessions: 1
  }]
}, {
  type: 'S',
  description: 'Silver',
  includedSessions: 1,
  teacherHelpline: true,
  baseSubscriptionType: SubscriptionType.ELS,
  basePrice: 59,
  extraSessionCost: [{
    cost: 15,
    numberOfSessions: 1
  }]
}, {
  type: 'B',
  description: 'Bronze',
  includedSessions: 1,
  teacherHelpline: false,
  baseSubscriptionType: SubscriptionType.ELB,
  basePrice: 44,
  extraSessionCost: [{
    cost: 15,
    numberOfSessions: 1
  }]
}]

const newBasePrice: Record<PlanWithPaymentPeriodsTypes,number> = {
  P: 69,
  G: 69,
  S: 69,
  B: 54
}

const plansMapperNewPricing = (t: typeof PERIOD_PLAN_TYPES_PILOT[0]) => PAYMENT_PERIODS.map<EstiaLabsPricingModel>(period => {
  return {
    modelType: 'estialabs',
    description: `${t.description} ${period} month${period > 1 ? 's' : ''} Subscription`,
    billingCycleLength: moment.duration({ months: 1 }),
    extraSessionCost: t.extraSessionCost,
    includedSessions: t.includedSessions,
    membershipFee: (newBasePrice[t.type] * (period < 12 ? 1.2 : 1)) + (t.extraSessionCost[0].cost * t.includedSessions),
    priceCode: `EL${t.type}${period}`,
    teacherHelpline: t.teacherHelpline,
    termLength: moment.duration({ months: period }),
    includesLearningSystem: true,
    baseSubscriptionType: t.baseSubscriptionType,
    validFrom: '2024-01-13'
  }
}
)

export const KLARNA_STRIPE_PAYMENT_LINKS: {
  /** link to stripe */
  link: string
  /** amount in dollars and cents */
  amount: number
}[] = [
  {
    link: 'https://buy.stripe.com/3cs9Bffr31aVaU8fZ1',
    amount: 372.60
  },
  {
    link: 'https://buy.stripe.com/14keVz6Ux1aVbYc3cg',
    amount: 453.60
  },
  {
    link: 'https://buy.stripe.com/7sI8xb0w96vf0fucMR',
    amount: 534.60
  },
  {
    link: 'https://buy.stripe.com/fZecNr3Il8DnfaofZ0',
    amount: 696.60
  },
]


const plansMapper = (t: typeof PERIOD_PLAN_TYPES[0]) => PAYMENT_PERIODS.map<EstiaLabsPricingModel>(period => {
  const extraSessionCost = t.extraSessionCost.map<EstiaLabsPricingModel['extraSessionCost'][0]>((s) => {
    return {
      numberOfSessions: s.numberOfSessions,
      cost: period < 12 ? s.cost * 1.2 : s.cost
    }
  })
  return {
    modelType: 'estialabs',
    description: `${t.description} ${period} month${period > 1 ? 's' : ''} Subscription`,
    billingCycleLength: moment.duration({ months: 1 }),
    extraSessionCost,
    includedSessions: t.includedSessions,
    membershipFee: extraSessionCost[0].cost * t.includedSessions + 35,
    priceCode: `EL${t.type}${period}`,
    teacherHelpline: t.teacherHelpline,
    termLength: moment.duration({ months: period }),
    includesLearningSystem: true,
    baseSubscriptionType: t.baseSubscriptionType,
    validTo: '2024-01-14'
  }
}
)

export const ESTIALABSPLANS: EstiaLabsPricingModel[] = [
  ...PERIOD_PLAN_TYPES.flatMap(plansMapper),
  ...PERIOD_PLAN_TYPES_PILOT.flatMap(plansMapperNewPricing),
  {
    modelType: 'estialabs',
    extraSessionCost: [{
      cost: 25,
      numberOfSessions: 1
    }, {
      cost: 200,
      numberOfSessions: 10
    }],
    includedSessions: 0,
    membershipFee: 0,
    priceCode: 'ELNM',
    description: 'Non-Member',
    teacherHelpline: false,
    includesLearningSystem: false,
    baseSubscriptionType: SubscriptionType.ELNM,
    noDirectDebit: true
  }
]

/** alternaitve set of plans for p2p code */
export const ESTIALABSPLANS_PILOT: EstiaLabsPricingModel[] = [
  ...PERIOD_PLAN_TYPES_PILOT.flatMap(t => PAYMENT_PERIODS.map<EstiaLabsPricingModel>(period => {
    return {
      modelType: 'estialabs',
      description: `${t.description} ${period} month${period > 1 ? 's' : ''} Subscription`,
      billingCycleLength: moment.duration({ months: 1 }),
      extraSessionCost: t.extraSessionCost,
      includedSessions: t.includedSessions,
      membershipFee: (t.basePrice * (period < 12 ? 1.2 : 1)) + (t.extraSessionCost[0].cost * t.includedSessions),
      priceCode: `EL${t.type}${period}`,
      teacherHelpline: t.teacherHelpline,
      termLength: moment.duration({ months: period }),
      includesLearningSystem: true,
      baseSubscriptionType: t.baseSubscriptionType
    }
  }
  ))
]

const BASE_PLAN_CODES_AND_INCLUDED_SESSIONS: {
  baseSubscriptionType: SubscriptionType,
  includedSessions: number
}[] = [
    ...PERIOD_PLAN_TYPES_PILOT,
    {
      baseSubscriptionType: SubscriptionType.ELP12C,
      includedSessions: 4
    }
  ]


// old pricing plan
// ESTIALABSPLANS.push({
//   ...ESTIALABSPLANS.find(p => p.priceCode === 'ELP12' && p.validTo === '2024-01-14'),
//   priceCode: 'ELP12C',
//   baseSubscriptionType: SubscriptionType.ELP12C,
//   upfrontPayment: {
//     amount: 799,
//     period: moment.duration({ months: 12 })
//   },
// })
// // new pricing plan
// ESTIALABSPLANS.push({
//   ...ESTIALABSPLANS.find(p => p.priceCode === 'ELP12' && p.validTo === undefined),
//   priceCode: 'ELP12C',
//   baseSubscriptionType: SubscriptionType.ELP12C,
//   upfrontPayment: {
//     amount: 899,
//     period: moment.duration({ months: 12 })
//   },
// })
// ESTIALABSPLANS_PILOT.push({
//   ...ESTIALABSPLANS_PILOT.find(p => p.priceCode === 'ELP12' && p.validTo === undefined),
//   priceCode: 'ELP12C',
//   baseSubscriptionType: SubscriptionType.ELP12C,
//   upfrontPayment: {
//     amount: 799,
//     period: moment.duration({ months: 12 })
//   },
// })

const estiaPlanToPromoCodeMapper = (plan:EstiaLabsPricingModel): IrisPromoCodes => ({
  code: plan.priceCode,
  valueDefault: null,
  valueApplied: plan.priceCode,
  path: 'finance.paymentMethod'
})


// 1 price code per code combination
export const PROMO_CODES: IrisPromoCodes[] = [
  {
    code: 'COMP',
    valueApplied: true,
    valueDefault: false,
    path: 'bypassPayment',
    isValid(state, getters) {
      return [
        SubscriptionType.ELB,
        SubscriptionType.ELP,
        SubscriptionType.ELG,
        SubscriptionType.ELS
      ].includes(getters.currentNewPricingPlan?.baseSubscriptionType)
    }
  },
  ...ESTIALABSPLANS.map(estiaPlanToPromoCodeMapper),
  {
    code: 'P2P',
    valueApplied: true,
    valueDefault: false,
    path: 'phase2pilot'
  },
  {
    code: 'PU23',
    valueApplied: '2024-01-12',
    valueDefault: null,
    path: 'finance.pricingDate',
    validTo: '2024-04-22'
  },
  {
    code: 'CSS20',
    path: 'discount',
    valueApplied: 20,
    valueDefault: null
  },
  {
    code: 'DISCOUNT10',
    path: 'discount',
    valueApplied: 10,
    valueDefault: null
  },
  {
    code: 'SCHOOL20',
    path: 'discount',
    valueApplied: 20,
    valueDefault: null
  },
  {
    code: 'SCHOOL10',
    path: 'discount',
    valueApplied: 10,
    valueDefault: null
  },
  {
    code: 'COMMUNITY10',
    path: 'discount',
    valueApplied: 10,
    valueDefault: null
  },
  {
    code: '1MONTHFREE',
    path: ['overrideInitialPaymentAmount', 'monthsInitialPayment'],
    valueApplied: [0, 1],
    valueDefault: [null, 1]
  },
  {
    code: 'KLARNA',
    path: ['discount', 'monthsInitialPayment'],
    valueApplied: [10, 6],
    valueDefault: [null, 1],
    isValid(_state, getters) {
      return getters.currentNewPricingPlan?.termLength.asMonths() === 12
    }
  },
  {
    code: 'CAAT',
    isValid (_state, getters) {
      return getters.currentNewPricingPlan?.priceCode === 'ELP12'
    }
  },
  ...BASE_PLAN_CODES_AND_INCLUDED_SESSIONS.flatMap<IrisPromoCodes>((basePlan => {
    return [
      {
        code: 'FREE121',
        path: ['numberOfSessionsOverride', 'discount'],
        valueApplied: [basePlan.includedSessions + 1, null],
        valueDefault: [null, null],
        isValid(state, getters) {
          return getters.currentNewPricingPlan?.baseSubscriptionType === basePlan.baseSubscriptionType && !state.promoCodes.includes('FREE121')
        }
      },
      {
        code: 'ESTIA10',
        path: ['numberOfSessionsOverride', 'discount'],
        valueApplied: [basePlan.includedSessions + 1, 10],
        valueDefault: [null, null],
        isValid(state, getters) {
          return getters.currentNewPricingPlan?.baseSubscriptionType === basePlan.baseSubscriptionType && !state.promoCodes.includes('ESTIA10')
        }
      },
      {
        code: 'ESTIA05',
        path: ['numberOfSessionsOverride', 'discount'],
        valueApplied: [basePlan.includedSessions + 1, 5],
        valueDefault: [null, null],
        isValid(state, getters) {
          return getters.currentNewPricingPlan?.baseSubscriptionType === basePlan.baseSubscriptionType && !state.promoCodes.includes('ESTIA05')
        }
      }
    ]
  }))
]

/** Payment methods allowed for stripe subscriptions */
export const ALLOWED_STRIPE_SUBSCRIPTION_PAYMENT_METHODS: Stripe.PaymentMethod.Type[] = ['bacs_debit']

export const STEP_FIND_PRESENTATION = -2
export const STEP_GET_INITIAL_LOCATION = -1
export const STEP_PRIVACY_PROMPT = 0
export const STEP_FAMILY_INFORMATION = 1
export const STEP_UNLOCK = 2
export const STEP_CONFIRM_EMAILS_RECEIVED = 3
export const STEP_FINANCE = 4
export const STEP_CHOOSE_SESSIONS = 14
export const STEP_SIGNING = 5
export const STEP_LTL_VIDEO_CONFIRMATION = 6
export const STEP_UPLOADING_DOCUMENTS = 7
export const STEP_COMPLETED_NORMAL = 8
export const STEP_COMPLETED_SAVED = 9
export const STEP_OFFICE_PROCESSED_REFERRED = 10
export const STEP_COMPLETED_SENT_TO_CUSTOMER = 11
export const STEP_CUSTOMER_SIGNING = 12
export const STEP_CANCELLED_ACCOUNT = 13

export const MAIN_STEPS = [STEP_FIND_PRESENTATION, STEP_GET_INITIAL_LOCATION, STEP_PRIVACY_PROMPT, STEP_FAMILY_INFORMATION, STEP_UNLOCK, STEP_CONFIRM_EMAILS_RECEIVED, STEP_FINANCE, STEP_CHOOSE_SESSIONS, STEP_SIGNING, STEP_LTL_VIDEO_CONFIRMATION, STEP_UPLOADING_DOCUMENTS, STEP_COMPLETED_NORMAL]
